<template>
  <div>
    <div class="justify-content-around">
      <h2 class="text-center my-5 text-uppercase">Payments</h2>
      <!-- <button @click="paymentSheetTest">Payment sheet</button> -->
      <button @click="applePayTest">Create Apple pay</button>
      <!-- <button @click="presentApplePay">Present Apple pay</button> -->

      <button id="apple-pay-button" @click="beginApplePay"></button>
    </div>
  </div>
</template>
<script>
// import {
//   Stripe,
//   PaymentSheetEventsEnum,
//   ApplePayEventsEnum,
// } from "@capacitor-community/stripe";

const Stripe = window.Stripe;

export default {
  name: "PaymentTest",
  data() {
    return {
      stripe: null,
    };
  },
  async created() {
    Stripe.setPublishableKey("pk_test_TYooMQauvdEDq54NiTphI7jx");

    // await Stripe.initialize({
    //   publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
    // });
  },

  methods: {
    // async paymentSheetTest() {
    //   try {
    //     const customerId = "cus_MrOleXQZ0C0XJE";

    //     // be able to get event of PaymentSheet
    //     Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
    //       console.log("PaymentSheetEventsEnum.Completed");
    //     });

    //     // prepare PaymentSheet with CreatePaymentSheetOption.
    //     await Stripe.createPaymentSheet({
    //       paymentIntentClientSecret:
    //         "pi_3MsmjyCx5LaHOSNn1lj85B4s_secret_G21Gn3Q2regLDP58hEXs2ndXF",
    //       customerId: customerId,
    //       customerEphemeralKeySecret: "ephkey_1MtCkVCx5LaHOSNnvvscXUSd",
    //     });

    //     // present PaymentSheet and get result.
    //     const result = await Stripe.presentPaymentSheet();
    //     if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
    //       // Happy path
    //     }

    //     // const result = await Stripe.presentPaymentSheet();

    //     alert(JSON.stringify(result));
    //     console.log(result);
    //   } catch (err) {
    //     alert(err, err.message, err.stack);
    //     console.log(err);
    //     debugger;
    //   }
    // },

    async applePayTest() {
      try {
        Stripe.applePay.checkAvailability(function (available) {
          if (available) {
            document.getElementById("apple-pay-button").style.display = "block";
          }
        });
      } catch (err) {
        alert(err, err.message, err.stack);
        console.log(err);
        debugger;
      }
    },

    beginApplePay() {
      var paymentRequest = {
        countryCode: "US",
        currencyCode: "USD",
        total: {
          label: "Rocketship Inc",
          amount: "19.99",
        },
      };
      var session = Stripe.applePay.buildSession(
        paymentRequest,
        function (result, completion) {
          console.log(result, completion);
        },
        function (error) {
          console.log(error.message);
        }
      );

      session.oncancel = function () {
        console.log("User hit the cancel button in the payment window");
      };

      session.begin();
    },

    // async presentApplePay() {
    //   try {
    //     // Present Apple Pay
    //     const result = await Stripe.presentApplePay();
    //     if (result.paymentResult === ApplePayEventsEnum.Completed) {
    //       // Happy path
    //     }

    //     // be able to get event of PaymentSheet
    //     Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
    //       console.log("PaymentSheetEventsEnum.Completed");
    //     });
    //   } catch (err) {
    //     alert(err, err.message, err.stack);
    //     console.log(err);
    //     debugger;
    //   }
    // },
  },
};
</script>

<style>
#apple-pay-button {
  display: none;
  background-color: black;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-size: 100% 100%;
  background-origin: content-box;
  background-repeat: no-repeat;
  width: 100%;
  height: 44px;
  padding: 10px 0;
  border-radius: 10px;
}
</style>
